export function autoFocusDatepicker() {
    const birthdateInput = document.getElementById('birthdate');
    const pseudoBirthdateInput = document.getElementById('pseudo_birthdate');
    if (!birthdateInput || !pseudoBirthdateInput) {
        return;
    }

    birthdateInput.addEventListener('input', function () {
        const value = birthdateInput.value;
        if (!value || value.length === 0) {
            pseudoBirthdateInput.style.display = 'block';
        } else {
            pseudoBirthdateInput.style.display = 'none';
        }
    });
    birthdateInput.addEventListener('blur', function () {
        const value = birthdateInput.value;
        if (!value || value.length === 0) {
            pseudoBirthdateInput.style.display = 'block';
        }
    });
    birthdateInput.addEventListener('focus', function () {
        pseudoBirthdateInput.style.display = 'none';
        birthdateInput.focus();
    });
    pseudoBirthdateInput.addEventListener('focus', function () {
        pseudoBirthdateInput.style.display = 'none';
        birthdateInput.focus();
    });
}
